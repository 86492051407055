@header-color: #191919;

.page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    line-height: 56px;
    padding: 0 24px 0 0;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    color: @header-color;

    .left {
        display: flex;
        align-items: center;

        img {
            height: 30px;
            margin: 0 4px 0 20px;
            border: 0px !important;
        }

        .system-name {
            font-size: 20px;
            font-weight: 500;
            color: #444444;
            text-decoration: none;
            display: block;
            width: 45px;
        }
    }

    .content {
        color: #00a0ff;
        text-decoration: underline;
        padding: 0px 20px;
        list-style: none;
        font-size: 20px;
    }

    .center {
        .center-menu {
            background: transparent !important;
            border: 0px !important;
            width: 350px;
            font-size: 16px;
        }
    }

    .right {
        display: flex;
        align-items: center;
        gap: 32px;
        font-size: 16px;

        svg {
            font-size: 20px;
        }

        .item {
            cursor: pointer;
            color: @header-color;
            text-decoration: none;
        }

        .item-line {
            height: 16px;
            border-color: @header-color;
        }
    }
}

.app-store-container {
    width: 350px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 16px;
    margin-top: 24px;
    border: 1px solid #e9e9e9;

    .app-item {
        width: 110px;
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        gap: 10px;
        cursor: pointer;

        &:hover {
            background-color: #8ce0e0;
        }

        .logo-item {
            font-size: 64px;
        }
    }
}

.mobile-766 {
    .page-header {
        .center {
            .center-menu {
                max-width: 130px;
                width: auto;
            }
        }
    }
}
