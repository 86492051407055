::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/*定义滚动条轨道内阴影+圆角*/
::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}

/*定义滑块内阴影+圆角*/
::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

body,
html {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

html::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
}

.ant-pro-layout .ant-pro-sider.ant-pro-sider-fixed {
    position: fixed;
    inset-block-start: auto !important;
    inset-inline-start: 0;
    z-index: 100;
    height: calc(100% - 64px) !important;
}

.page-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 4px;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
