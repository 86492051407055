.main-app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .background-content {
        position: fixed;
        z-index: -100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: url(/assets/bg-3502b275.jpg) center top no-repeat;
        background-size: 100vw;
        background-size: cover;
        background: linear-gradient(
            90deg,
            rgb(224, 243, 243) 0%,
            0.77381%,
            rgb(222, 240, 241) 1.54762%,
            6.72619%,
            rgb(219, 236, 239) 11.9048%,
            12.381%,
            rgb(217, 234, 235) 12.8571%,
            27.9167%,
            rgb(215, 232, 234) 42.9762%,
            51.9048%,
            rgb(213, 230, 233) 60.8333%,
            61.9643%,
            rgb(211, 228, 232) 63.0952%,
            66.7262%,
            rgb(207, 226, 231) 70.3571%,
            73.2738%,
            rgb(205, 224, 230) 76.1905%,
            77.1429%,
            rgb(203, 222, 229) 78.0952%,
            79.9405%,
            rgb(201, 220, 228) 81.7857%,
            84.1667%,
            rgb(199, 218, 227) 86.5476%,
            87.0238%,
            rgb(197, 216, 226) 87.5%,
            89.3452%,
            rgb(195, 214, 226) 91.1905%,
            95.5952%,
            rgb(193, 212, 225) 100%
        );
    }

    .main-app-main {
        flex: 1 1 auto;
        overflow-x: hidden;
        position: relative;
        box-sizing: border-box;
    }
}

.mainapp-loading {
    color: #444;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}
