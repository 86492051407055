.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 15%;
    font-size: 14px;

    img {
        width: 160px;
        height: 160px;
    }
}
